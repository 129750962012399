footer {
    margin-top: 2em;
    padding: 1.5em 0;
    color: white;
    background-color: black;
}
footer h5{text-align: center}
.box img {
    max-width: 100px;
}

    .box{

        margin:0 auto;
        position:relative;
    }
    
    .box .left{
        display: inline-block;
    }
    .box .center{
        
        margin:0 100px 0 100px;
        display: inline-block;
    }
    .box .right{
     
        display: inline-block;
    }
    
     div{
        text-align:center;
    }
