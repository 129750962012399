@tailwind base;
@tailwind components;
@tailwind utilities;
.landing {
    background-image: url('https://www.jambiupdate.co/foto_berita/2018/05/10/63job-fair.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    font-family: Neonderthaw;
    position: relative;
}
.container{
    width:100% ;
    height: 20vw;
    margin:5vw ;
    box-shadow: 3px 3px 11px 0px rgba(7, 7, 7, 0.81);
}
.container>h1{
    background: #fed369;
    background: -moz-linear-gradient(to right,#55acee 0,#da84ab 50%,#fcac5e 100%);
    background: -webkit-linear-gradient(to right,#55acee 0,#da84ab 50%,#fcac5e 100%);
    background: linear-gradient(to right,
#55acee 0,#da84ab 50%,
#fcac5e 100%);
    background-clip: border-box;
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00626b', endColorstr='#00f7ca', GradientType=1 );
background-clip: text;
-webkit-background-clip: text;
text-fill-color: transparent;
-webkit-text-fill-color:
    transparent;
    text-align: left;
    display: inline-block;
    padding-right:5vw ;
    margin-bottom: 6vw;
}




h1 {
    margin-bottom: 2vw;
    font-size: 5vw;
}

