.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.topnav {
  display: flex;
  background-color: #ffffff;
  position: sticky;
  top: -5px;
  margin: 0;
  box-shadow:
  0 37px 37px -4px rgba(0, 0, 0, 0.02)
;




}

/* Style the topnav links */
.topnav a {
  float: left;
  display: block;
  color: #333;
  text-align: center;
  padding: 24px 26px;
  text-decoration: none;
}

.topnav form {
  position: absolute;
  right: 0;
  height: 100%;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Change color on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}
.containerfom{
  width:85%;
  margin-left:220px;
  margin-right:20px;
}
.inputData {
  width: 25%;
margin: 0 auto;
text-align: center;
background-color:#eee ;
}
.inputform>input[type="password"]{
width: 60%;
display: block;
margin: 0 auto;


}
.inputform>input[type="password"]:hover{
transform: scale(1.1);
background-color: #ddd;
transition: transform 1s;
}
.inputform>input[type=password], select {
  width: 60%;
  display: block;
  padding: 12px 20px;
  margin-left: 7vw;
  
  border: 1px solid rgb(91, 245, 64);
  border-radius: 4px;
  box-sizing: border-box;
}

.inputform>input[type=submit] {
  width: 30%;
  margin: 0;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inputform>input[type=submit]:active {
  background-color: rgba(199, 18, 190, 0.671);
  transform: scale(0.8);
  transition: transform 1s;
}
label{
  font-weight: bold;
  font-family:Segoe UI, Tahoma, Geneva, Verdana, sans-seri;
  font-size: 20px;

}
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
form> #select,#filter{
  display:inline-block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}
@media screen and (max-width: 700px) {
  .containerfom{margin-left:0;
    margin-right:20px;

  }
  #Text{
    display:none;
  }
  #NavLanding{
display: none;
  }
  #Foto{
    width: 25px;
    text-align: center;
  }
  .box .center{
        
    margin:0 0px 0px 0px;
    display: inline-block;
}
#LowonganDetail{ margin-right: 5vw;
}
#LowonganDetail>h1{
  font-size:6vw;
}
#Getstar>Link{
  height: 15px;
  width: 35px;
}
#InputFom{
  width: 75%;
  margin-right:2vw;
}
}